import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  table: {
    //minWidth: 650,
  },  
});

export default function Films() {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">        
        {/* <TableHead>
          <TableRow>
            <TableCell>Experience</TableCell>
          </TableRow>
        </TableHead>  */}

        <TableBody>            
            <TableRow>              
              <TableCell><TableHead>Principle actor</TableHead>             
              Films: Eternal Muse, Echo, Where The Ivy Grows, Ruby, New Home Adventures<br/>
              Docudrama: Catching Killers, Encounters, Hughie Green<br/>
              Commercials: Soho Square, Derwent London, Maxwood<br/>
              Music Video: Henry Moodie - Drunk Text
              </TableCell>
            </TableRow>                   
        </TableBody>



         <TableBody>            
            <TableRow>              
              <TableCell><TableHead>Featured</TableHead>Mission Impossible Dead Reckoning, Malice, One Life, Pack of Lies, Apartment 7a, One Day, I Hate Suzi</TableCell>
            </TableRow>                   
        </TableBody>
             
        <TableBody>
            <TableRow>              
              <TableCell>Actor double: Promises, Hijack</TableCell>
            </TableRow>            
        </TableBody>

        {/* <TableBody>            
            <TableRow>              
              <TableCell>
                Maxwood Commercial: &nbsp;
                <Link to={{ pathname: "https://vimeo.com/657519404" }} style={{ textDecoration:'underline', color:'#fff', fontWeight:'bold' }} target="_blank" >
                 https://vimeo.com/657519404
                </Link>

                <br/>
                Ruby (extracts): &nbsp;
                <Link to={{ pathname: "https://vimeo.com/736690588" }} style={{ textDecoration:'underline', color:'#fff', fontWeight:'bold' }} target="_blank" >
                https://vimeo.com/736690588
                </Link>
              
              </TableCell>
            </TableRow>                   
        </TableBody> */}



       {/* <TableBody>
            <TableRow>              
              <TableCell><TableHead>Background</TableHead>The Split, Blithe Spirit, Quiz, Death On The Nile, A Gift From Bob, Roadkill, Sitting In Limbo, The Duke, Suspicion, The Girlfriend Experience, Angela Black, Call The Midwife, Starstruck, Safe Space, Johnny Cash & Metallica - Breaking The Band, Slow Horses, Killing Eve s4, Indiana Jones 5, Breeders, Magic Mike 3, Dr Who, Luther, You, The Crown, Bridgerton, Billions</TableCell>
            </TableRow>            
        </TableBody> */}
            
        <TableBody>
            <TableRow>              
              <TableCell>Screen Combat: Take 3 Action Academy: 2 vs 1 - Street Open Armoury. British Acadamy of Dramatic Combat: Unarmed, Rapier & Dagger. Gun handling. Military reenactment</TableCell>              
            </TableRow>
            <TableRow>              
              <TableCell>Accents: RP (native), General American</TableCell>              
            </TableRow>
            <TableRow>              
              <TableCell>Location: West London</TableCell>              
            </TableRow>            
        </TableBody>
      </Table>

    </TableContainer>



 

    

    
  );
}

/*<TableBody>         
            <TableRow>              
              <TableCell>The Split (TV)</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>Blithe Spirit (Film)</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>The English Game (Netflix)</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>Quiz (TV)</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>Death on the Nile (Film)</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>I Hate Suzi (TV)</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>A Gift From Bob (Film)</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>Sitting In Limbo (TV)</TableCell>
            </TableRow>
            <TableRow> 
              <TableCell>Roadkill (TV)</TableCell>
            </TableRow>
            <TableRow>              
              <TableCell>The Duke (Film)</TableCell>
            </TableRow>
        </TableBody> 
*/
